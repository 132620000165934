







































































import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'

@Component
export default class AutomateInvoicingConfig extends Vue {
  saveIsLoading = false

  inputInvoiceWebshopOrders = null
  inputInvoiceE2eOrders = null
  inputDelayInvoicingDays = null
  inputRequireAttestation = null
  inputEmailTo = null
  inputEmailWhen = null
  inputEmailCustomers = null
  emailWhenOptions = []

  mounted() {
    this.$axios.get('/v3/economy/automate_invoicing/config/get_initial_data').then((response) => {
      const automateInvoicingConfig = response.data.data.automateInvoicingConfig
      if (automateInvoicingConfig !== null) {
        this.inputInvoiceWebshopOrders = automateInvoicingConfig.invoiceWebshopOrders
        this.inputInvoiceE2eOrders = automateInvoicingConfig.invoiceE2eOrders
        this.inputDelayInvoicingDays = automateInvoicingConfig.delayInvoicingDays
        this.inputRequireAttestation = automateInvoicingConfig.requireAttestation
        this.inputEmailTo = automateInvoicingConfig.emailTo
        this.inputEmailWhen = automateInvoicingConfig.emailWhen
        this.inputEmailCustomers = automateInvoicingConfig.emailCustomers
      }
      this.emailWhenOptions = response.data.data.emailWhenOptions
    })
  }

  save(e) {
    this.saveIsLoading = true
    e.preventDefault()

    const data = {
      invoiceWebshopOrders: this.inputInvoiceWebshopOrders,
      invoiceE2eOrders: this.inputInvoiceE2eOrders,
      delayInvoicingDays: this.inputDelayInvoicingDays,
      requireAttestation: this.inputRequireAttestation,
      emailTo: this.inputEmailTo,
      emailWhen: this.inputEmailWhen,
      emailCustomers: this.inputEmailCustomers,
    }

    this.$axios
      .post('/v3/economy/automate_invoicing/config/save', data)
      .then((response) => {
        vxm.alert.success({
          content: this.$t('c:common:Successfully saved') as string,
          title: this.$t('c:common:Success') as string,
        })
      })
      .catch((error) => {
        error.response.data.errors.forEach((v, i) => {
          vxm.alert.error({
            content: v.message as string,
            title: this.$t('c:common:Error') as string,
          })
        })
      })
      .finally(() => {
        this.saveIsLoading = false
      })
  }
}
